import { graphql, useStaticQuery } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';
import { breakpoints } from '../../styles/config';

const ImageContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: none;

    @media (min-width: ${breakpoints.laptop}px) {
        display: block;
    }
`;

interface HeroImageData {
    file: {
        childImageSharp: {
            fluid: FluidObject | FluidObject[];
        };
    };
}

export default function HeroImage(): JSX.Element | null {
    const data = useStaticQuery<HeroImageData>(graphql`
        query HeroImageQuery {
            file(sourceInstanceName: { eq: "assets" }, name: { eq: "woman" }) {
                childImageSharp {
                    fluid(maxWidth: 1366) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                }
            }
        }
    `);
    return (
        <ImageContainer data-testid="image">
            <Img
                fluid={data.file.childImageSharp.fluid}
                imgStyle={{ objectPosition: 'bottom right', objectFit: 'contain' }}
                alt=""
                style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', overflow: 'hidden' }}
                aria-hidden={true}
            />
        </ImageContainer>
    );
}
