import isEmail from 'validator/es/lib/isEmail';

export type ValidatorFn = (value: any | any[]) => null | string | string[];

function required(value: string | Array<any>): null | string {
    if (value != null && value.length > 0) return null;
    return 'Field is required!';
}

function email(value: string): null | string {
    return isEmail(value) ? null : 'Field must be a valid email!';
}

function isString(value: any): value is string {
    return typeof value === 'string' && value !== '';
}

function compose(...fns: ValidatorFn[]): ValidatorFn {
    return value => {
        const errors = fns.map(fn => fn(value)).filter(isString);
        return errors.length ? errors : null;
    };
}

const Validators = {
    required,
    email,
    compose,
};

export default Validators;
