import React from 'react';
import styled from 'styled-components';
import Text from '../typography/Text';
import Title from '../typography/Title';
import Block from '../Block';
import { constructPath } from '../../i18n/util';
import { useI18n } from '../../i18n/useI18n';
import ActionLink from '../ActionLink';
import { breakpoints, desktop, tablet } from '../../styles/config';
import { SocialMediaDisplay } from '../SocialMediaDisplay';
import { RemarkNode } from '../../models/RemarkNode';
import { Main } from '../Main';
import HeroImage from './HeroImage';
import HeroImageJpg from './hero_background.jpg';
import HeroImageWebp from './hero_background.webp';

export interface HeroProps {
    title: string;
    text: RemarkNode;
    cta: string;
    className?: string;
}

const HeroContainer = styled(Main)`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    min-height: calc(100vh - 60px);
    width: 100%;
    background-image: url(${HeroImageJpg});
    background-image: url(${HeroImageWebp});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    @media (min-width: ${breakpoints.tablet}px) {
        min-height: calc(100vh - 60px);
    }

    @media (min-width: ${breakpoints.desktop}px) {
        margin-top: -144px;
        min-height: 100vh;
    }
`;

const ContentContainer = styled(Block)`
    position: relative;
    margin-top: 2rem;

    ${tablet`
        margin-top: -30px;
    `};
    ${desktop`
        margin-top: -40px;
    `};
`;

const SocialMediaContainer = styled(Block)`
    position: relative;
`;

const HeroTitle = styled(Title)`
    margin-bottom: 1.5rem;
    max-width: 600px;
`;

const HeroText = styled(Text)`
    margin-bottom: 1.5rem;
    font-size: 18px;
    line-height: 1.5em;
    max-width: 600px;
`;

const EmptySpacer = styled.div`
    display: none;
    ${tablet`
        display: block;
    `};
`;

const Hero: React.FC<HeroProps> = ({ title, text, cta, className }) => {
    const { language, t } = useI18n();
    const to = constructPath('index', language) + '#' + t('nav.contact').toLowerCase();
    const scrollToContact = (e: React.MouseEvent) => {
        e.preventDefault();
        const contactFromTop =
            document.getElementById(t('nav.contact').toLowerCase())?.getBoundingClientRect().top || 0;
        window.scrollBy({
            top: contactFromTop,
            behavior: 'smooth',
        });
    };
    return (
        <HeroContainer className={className}>
            <EmptySpacer />

            <HeroImage />

            <ContentContainer>
                <HeroTitle level={1}>{title}</HeroTitle>
                <HeroText dangerouslySetSanitizedHTML={text.childMarkdownRemark.html} />
                <ActionLink to={to} onClick={scrollToContact}>
                    {cta}
                </ActionLink>
            </ContentContainer>

            <SocialMediaContainer>
                <SocialMediaDisplay isBig={true} />
            </SocialMediaContainer>
        </HeroContainer>
    );
};

export default Hero;
