import styled, { css } from 'styled-components';
import { breakpoints } from '../../styles/config';

export const HomeReviewsHeader = styled.div`
    @media (min-width: ${breakpoints.tablet}px) {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;

        & > * {
            flex: none;
            width: 50%;
        }

        h2 {
            order: 2;
            padding-left: 1.5rem;
        }
    }

    @media (min-width: ${breakpoints.desktop}px) {
        h2 {
            padding-left: 3rem;
        }
    }
`;

export const HomeReviewsActions = styled.div`
    @media (min-width: ${breakpoints.tablet}px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10%;
    }
`;
