import React from 'react';
import { useI18n } from '../../i18n/useI18n';
import { FormActions, Input, Label, TextArea } from '../Forms';
import { ButtonWithArrow } from '../Button';
import Validators, { ValidatorFn } from '../util/Validators';
import useAddNotification from '../notification/Notification';

const VALIDATORS: { [key: string]: ValidatorFn } = {
    name: Validators.required,
    email: Validators.compose(Validators.required, Validators.email),
    subject: Validators.required,
    message: Validators.required,
};

function initialFormValue() {
    return {
        name: '',
        email: '',
        subject: '',
        message: '',
    };
}

export function ContactForm(): JSX.Element {
    const { t, language } = useI18n();
    const [loading, setLoading] = React.useState(false);
    const [value, setValue] = React.useState(initialFormValue);
    const addNotification = useAddNotification();

    const errors = React.useMemo(() => {
        return Object.fromEntries(Object.entries(value).map(([key, value]) => [key, VALIDATORS[key](value)]));
    }, [value]);

    const hasErrors = React.useMemo(() => {
        return Object.values(errors).some(error => error !== null);
    }, [errors]);

    const handleChange = React.useCallback((event: any) => {
        const name = event.target.name;
        const value = event.target.value;
        setValue(values => ({
            ...values,
            [name]: value,
        }));
    }, []);

    const handleSubmit = React.useCallback(
        async (event: any) => {
            event.preventDefault();
            if (hasErrors) return;

            setLoading(true);
            const response = await fetch('/api/contact', {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify({ ...value, language }),
            });
            setLoading(false);

            if (response.status >= 200 && response.status < 300) {
                setValue(initialFormValue());
                addNotification('success', t('contact.success'));
            } else {
                console.error(response.status, response.statusText);
                console.error(response.body);
                addNotification('error', t('contact.error'));
            }
        },
        [value]
    );

    return (
        <form onSubmit={handleSubmit}>
            <Label htmlFor="contactName">{t('contact.name')}</Label>
            <Input id="contactName" value={value.name} name="name" onChange={handleChange} isInvalid={!!errors.name} />
            <Label htmlFor="contactEmail">{t('contact.email')}</Label>
            <Input
                id="contactEmail"
                value={value.email}
                name="email"
                onChange={handleChange}
                isInvalid={!!errors.email}
            />
            <Label htmlFor="contactSubject">{t('contact.subject')}</Label>
            <Input
                id="contactSubject"
                value={value.subject}
                name="subject"
                onChange={handleChange}
                isInvalid={!!errors.subject}
            />
            <Label htmlFor="contactMessage">{t('contact.message')}</Label>
            <TextArea
                rows={6}
                id="contactMessage"
                value={value.message}
                name="message"
                onChange={handleChange}
                isInvalid={!!errors.message}
            />
            <FormActions align="flex-end">
                {/* @ts-ignore */}
                <ButtonWithArrow loading={loading} variant="primary" type="submit">
                    {t('contact.send-message')}
                </ButtonWithArrow>
            </FormActions>
        </form>
    );
}
