import styled, { css } from 'styled-components';
import { breakpoints, colors, fonts } from '../../styles/config';
import { Link } from 'gatsby';

export const Container = styled.section`
    @media (min-width: ${breakpoints.tablet}px) {
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }

    @media (min-width: ${breakpoints.desktop}px) {
    }
`;

export const FormInfo = styled.p`
    font-size: 12px;
    font-weight: 300;
    font-family: ${fonts.lato}, sans-serif;
    line-height: 1.4;
    color: ${colors.grey60};

    svg {
        margin-right: 0.5em;
    }
`;

export const ContactBlock = styled.div<{ isBlue?: boolean; second?: boolean }>`
  padding: 3rem 1rem;
  color: ${p => (p.isBlue ? colors.white : colors.black)};
  background: ${p => (p.isBlue ? colors.blue : colors.grey6)};

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 3rem 2rem;
    order: ${p => (p.second ? 1 : 0)};
    flex: none;
    width: 50%;
  }

  @media (min-width: ${breakpoints.desktop}px) {
    padding-${p => (p.second ? 'right' : 'left')}: calc(((100vw - ${breakpoints.desktop}px) / 2) + 2rem);
  }
`;

export const HelpList = styled.div`
    margin-bottom: 2rem;
`;

export const HelpItem = styled(Link)`
    position: relative;
    display: block;
    padding: 1rem 2rem 1rem 0;

    border-bottom: 1px solid ${colors.white};

    &:first-child {
        border-top: 1px solid ${colors.white};
    }

    &,
    &:hover,
    &:focus,
    &:visited {
        color: ${colors.white};
        text-decoration: none;
    }

    svg {
        position: absolute;
        top: 50%;
        right: 0.5rem;
        transform: translateY(-50%);
        font-size: 1rem;
    }
`;

export const HelpTitle = styled.h3`
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0.7em;
`;
export const HelpText = styled.p`
    font-size: 14px;
    font-weight: 300;
    line-height: 1.3em;
`;

export const ContactInfo = styled.div<{ grid?: boolean }>`
    line-height: 1.4em;

    &:not(:last-child) {
        margin-bottom: 0.8em;
    }

    strong {
        font-size: 18px;
        font-weight: 700;
    }

    a,
    a:hover,
    a:visited,
    a:active,
    a:focus {
        color: white;
        text-decoration: underline;
        cursor: pointer;
    }

    ${p =>
        p.grid &&
        css`
            @media (min-width: ${breakpoints.laptop}px) {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
        `}
`;

export const GoogleMapsLink = styled.a`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    margin-top: 1rem;

    svg {
        font-size: 32px;
    }

    span {
        margin-left: 1rem;
    }

    @media (min-width: ${breakpoints.laptop}px) {
        margin-top: 0;
        flex-direction: column;
        span {
            margin-left: 0;
            margin-top: 0.5rem;
        }
    }
`;
