import styled from 'styled-components';

export const Subtitle = styled.p`
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.4em;
    margin-bottom: 3rem;
`;

export default Subtitle;
