import styled, { css } from 'styled-components';
import { colors } from '../styles/config';

export const Label = styled.label`
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    color: ${colors.grey60};
    font-weight: 700;
    line-height: 1.5em;
    margin-bottom: 0.25rem;
`;

const BASE_INPUT_CSS = css`
    width: 100%;
    font-size: 14px;
    color: ${colors.black};
    background: ${colors.white};
    margin-bottom: 1rem;
`;

export const Input = styled.input<{ isInvalid?: boolean }>`
    ${BASE_INPUT_CSS};
    height: 52px;
    padding: 0 1rem;
    border: 1px solid ${p => (p.isInvalid ? colors.red : colors.white)};
`;

export const TextArea = styled.textarea<{ isInvalid?: boolean }>`
    ${BASE_INPUT_CSS};
    padding: 1rem;
    border: 1px solid ${p => (p.isInvalid ? colors.red : colors.white)};
    display: block;
    resize: vertical;
    line-height: 1.4em;
`;

export const FormActions = styled.div<{ align?: 'flex-start' | 'flex-end' }>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: ${p => p.align};
    margin-bottom: 1.5rem;

    > *:not(:last-child) {
        margin-right: 0.5rem;
    }
`;

FormActions.defaultProps = {
    align: 'flex-start',
};
