import React from 'react';
import { IContactData } from '../../models/IContactData';
import Title from '../typography/Title';
import {
    ContactBlock,
    ContactInfo,
    Container,
    FormInfo,
    GoogleMapsLink,
    HelpItem,
    HelpList,
    HelpText,
    HelpTitle,
} from './Contact.styles';
import { useI18n } from '../../i18n/useI18n';
import { constructPath } from '../../i18n/util';
import { faArrowRight, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContactForm } from './ContactForm';
import Subtitle from '../typography/Subtitle';

export interface ContactProps {
    data: IContactData;
}

export default function Contact({ data }: ContactProps): JSX.Element {
    const { i18n, t } = useI18n();
    const reviewsLink = constructPath('reviews', i18n.language);
    const plasticSurgeryLink = constructPath('plastic-surgery', i18n.language);
    const faqLink = constructPath('faq', i18n.language);

    return (
        <Container id={t('nav.contact').toLowerCase()} tabIndex={-1}>
            <ContactBlock isBlue second>
                <Title level={2}>{data.helpTitle}</Title>
                <Subtitle>{data.helpText}</Subtitle>
                <HelpList>
                    <HelpItem to={reviewsLink}>
                        <HelpTitle>{data.helpReviewsTitle}</HelpTitle>
                        <HelpText>{data.helpReviewsText}</HelpText>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </HelpItem>
                    <HelpItem to={plasticSurgeryLink}>
                        <HelpTitle>{data.helpPlasticSurgeryTitle}</HelpTitle>
                        <HelpText>{data.helpPlasticSurgeryText}</HelpText>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </HelpItem>
                    <HelpItem to={faqLink}>
                        <HelpTitle>{data.helpFaqTitle}</HelpTitle>
                        <HelpText>{data.helpFaqText}</HelpText>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </HelpItem>
                </HelpList>
                <ContactInfo grid>
                    <div>
                        <strong>{data.contactName}</strong>
                        <br />
                        {data.contactAddressStreet}
                        <br />
                        {data.contactAddressTown}
                        <br />
                    </div>
                    <GoogleMapsLink href="https://goo.gl/maps/pJoBtXCYGie4heXHA" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faMapMarkedAlt} />
                        <span>{t('contact.view-directions')}</span>
                    </GoogleMapsLink>
                </ContactInfo>
                <ContactInfo>
                    <a href={`mailto:${data.contactEmail}`}>{data.contactEmail}</a>
                    <br />
                </ContactInfo>
                <ContactInfo>
                    <a href={`tel:${data.contactPhone}`}>{data.contactPhone}</a>
                </ContactInfo>
            </ContactBlock>
            <ContactBlock>
                <Title level={2}>{data.formTitle}</Title>
                <ContactForm />
                <FormInfo dangerouslySetInnerHTML={{ __html: data.formInfoNode.childMarkdownRemark.html }} />
            </ContactBlock>
        </Container>
    );
}
