import React from 'react';
import styled, { css } from 'styled-components';
import { GatsbyLinkProps, Link } from 'gatsby';
import { colors } from '../styles/config';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type ActionLinkVariation = 'primary' | 'secondary';

const variationStyles = (color: string, background: string, colorHover: string, backgroundHover: string) => css`
    color: ${color};
    background: ${background};
    border: 1px solid ${background};
    will-change: background, border-color, color;
    transition-property: background, border-color, color;
    transition-duration: 0.6s;
    transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);

    &:hover,
    &:focus,
    &:active,
    &.is-active {
        color: ${colorHover};
        background: ${backgroundHover};
        border-color: ${backgroundHover};
    }
`;

const activeLinkVariations = (variation: ActionLinkVariation = 'primary') => {
    switch (variation) {
        case 'primary':
            return variationStyles(colors.white, colors.blue, colors.white, colors.blueDarker);
        case 'secondary':
            return variationStyles(colors.white, colors.grey40, colors.white, colors.grey60);
    }
};

export interface StyledLinksProps {
    variation?: ActionLinkVariation;
}

const StyledLink = styled(Link)<StyledLinksProps>`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 320px;
    height: 3rem;
    padding: 1.5rem;
    text-decoration: none;
    ${props => activeLinkVariations(props.variation)}
`;

const ActionLinkIcon = styled(FontAwesomeIcon).attrs({
    icon: faArrowRight,
    fixedWidth: true,
})`
    font-size: 14px;
    color: inherit;
`;

export interface ActionLinkProps extends GatsbyLinkProps<any>, StyledLinksProps {}

export const ActionLink: React.FC<ActionLinkProps> = ({ children, ...props }) => {
    return (
        // @ts-ignore
        <StyledLink {...props}>
            <span>{children}</span>
            <ActionLinkIcon />
        </StyledLink>
    );
};

export default ActionLink;
