import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Hero from '../components/hero/Hero';
import { RemarkNode } from '../models/RemarkNode';
import { DatoCmsImage } from '../models/DatoCms';
import { HomeReviews } from '../components/home/HomeReviews';
import { IReview } from '../models/Review';
import HomeAbout from '../components/home/HomeAbout';
import Contact from '../components/contact/Contact';
import { IContactData } from '../models/IContactData';

export interface IndexProps {
    data: {
        datoCmsHome: {
            title: string;
            textNode: RemarkNode;
            ctaButton: string;
            aboutTitle: string;
            aboutExcerptNode: RemarkNode;
            aboutImage: DatoCmsImage;
            aboutGoToAboutMe: string;
            aboutGoToGallery: string;
            reviews: Array<IReview>;
            reviewsTitle: string;
            reviewsGoToReviews: string;
            seoMetaTags: any;
        };
        datoCmsContact: IContactData;
    };
}

const Index: React.FC<IndexProps> = ({ data }) => {
    return (
        <>
            <HelmetDatoCms seo={data.datoCmsHome.seoMetaTags} />
            <Hero title={data.datoCmsHome.title} text={data.datoCmsHome.textNode} cta={data.datoCmsHome.ctaButton} />
            <HomeAbout
                title={data.datoCmsHome.aboutTitle}
                text={data.datoCmsHome.aboutExcerptNode}
                image={data.datoCmsHome.aboutImage}
                actions={{
                    goToAbout: data.datoCmsHome.aboutGoToAboutMe,
                    goToGallery: data.datoCmsHome.aboutGoToGallery,
                }}
            />
            <HomeReviews
                title={data.datoCmsHome.reviewsTitle}
                reviews={data.datoCmsHome.reviews}
                viewReviewsAction={data.datoCmsHome.reviewsGoToReviews}
            />
            <Contact data={data.datoCmsContact} />
        </>
    );
};

export const query = graphql`
    query IndexPage($language: String!) {
        datoCmsHome(locale: { eq: $language }) {
            title
            ctaButton
            textNode {
                childMarkdownRemark {
                    html
                }
            }
            aboutTitle
            aboutExcerptNode {
                childMarkdownRemark {
                    html
                }
            }
            aboutImage {
                fluid(maxWidth: 550) {
                    ...GatsbyDatoCmsFluid
                }
            }
            aboutGoToAboutMe
            aboutGoToGallery
            reviewsTitle
            reviewsGoToReviews
            reviews {
                ...ReviewFields
            }
            seoMetaTags {
                ...GatsbyDatoCmsSeoMetaTags
            }
        }
        datoCmsContact(locale: { eq: $language }) {
            formTitle
            formInfoNode {
                childMarkdownRemark {
                    html
                }
            }
            helpTitle
            helpText
            helpReviewsTitle
            helpReviewsText
            helpPlasticSurgeryTitle
            helpPlasticSurgeryText
            helpFaqTitle
            helpFaqText
            contactName
            contactAddressStreet
            contactAddressTown
            contactEmail
            contactPhone
        }
    }
`;

export default Index;
