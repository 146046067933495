import React from 'react';
import { IReview } from '../../models/Review';
import { useI18n } from '../../i18n/useI18n';
import { Review } from '../review/Review';
import { Section } from '../Block';
import Title from '../typography/Title';
import { HomeReviewsHeader, HomeReviewsActions } from './HomeReviews.styles';
import { ButtonWithArrow } from '../Button';
import { constructPath } from '../../i18n/util';
import ReviewContainer from '../review/ReviewContainer';
import { Link } from 'gatsby';

export interface HomeReviewsProps {
    title: string;
    reviews: IReview[];
    viewReviewsAction: string;
}

export function HomeReviews({ title, reviews, viewReviewsAction }: HomeReviewsProps): React.ReactElement | null {
    const { i18n } = useI18n();
    const [first, ...rest] = reviews;
    const reviewsLink = constructPath('reviews', i18n.language);

    return (
        <Section largeSpacing withGradient>
            <HomeReviewsHeader>
                <Title level={2}>{title}</Title>
                <ReviewContainer>
                    <Review {...first} align="left" currentLanguage={i18n.language} />
                </ReviewContainer>
            </HomeReviewsHeader>
            {rest.map((review, index) => (
                <ReviewContainer key={review.id} isPulledRight={index % 2 === 0}>
                    <Review {...review} currentLanguage={i18n.language} align={index % 2 === 0 ? 'right' : 'left'} />
                </ReviewContainer>
            ))}
            <HomeReviewsActions>
                <ButtonWithArrow as={Link} variant="primary" to={reviewsLink}>
                    {viewReviewsAction}
                </ButtonWithArrow>
            </HomeReviewsActions>
        </Section>
    );
}
