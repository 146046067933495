import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { Section } from '../Block';
import { breakpoints } from '../../styles/config';
import { DatoCmsImage } from '../../models/DatoCms';
import { RemarkNode } from '../../models/RemarkNode';
import Title from '../typography/Title';
import Text from '../typography/Text';
import { ButtonWithArrow } from '../Button';
import { constructPath } from '../../i18n/util';
import { useI18n } from '../../i18n/useI18n';

export interface AboutExcerptProps {
    title: string;
    text: RemarkNode;
    image: DatoCmsImage;
    actions: {
        goToAbout: string;
        goToGallery: string;
    };
}

const Container = styled(Section)`
    width: 100%;
    @media (min-width: ${breakpoints.tablet}px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
    @media (min-width: ${1024 + 32}px) {
        padding: 4.5rem calc((100vw - 1024px) / 2 + 2rem);
    }
    @media (min-width: ${breakpoints.desktop}px) {
        padding: 4.5rem calc((100vw - 1024px) / 2 + 2rem);
    }
`;

const TextContainer = styled.div`
    flex-grow: 1;
    flex-shrink: 1;
    @media (min-width: ${breakpoints.tablet}px) {
        padding-right: 2rem;
    }
`;

const ImageContainer = styled.div`
    flex-shrink: 0;
    width: 325px;
    max-width: 100%;
    order: 1;
    @media (max-width: ${breakpoints.tablet - 0.02}px) {
        margin: 0 auto 2rem;
    }
`;

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1.5rem;

    > a {
        margin-bottom: 1rem;
    }

    > a:not(:last-child) {
        margin-right: 1rem;
    }
`;

export const HomeAbout: React.FC<AboutExcerptProps> = ({ title, text, image, actions }) => {
    const { i18n, t } = useI18n();
    const aboutPath = constructPath('about', i18n.language);

    return (
        <Container largeSpacing withGradient>
            <ImageContainer>
                <Img {...image} />
            </ImageContainer>
            <TextContainer>
                <Title level={2}>{title}</Title>
                <Text dangerouslySetSanitizedHTML={text.childMarkdownRemark.html} />
                <Actions>
                    <ButtonWithArrow as={Link} variant="primary" to={aboutPath}>
                        {actions.goToAbout}
                    </ButtonWithArrow>
                    <ButtonWithArrow as={Link} variant="secondary" to={aboutPath + '#' + t('about.gallery-anchor')}>
                        {actions.goToGallery}
                    </ButtonWithArrow>
                </Actions>
            </TextContainer>
        </Container>
    );
};

export default HomeAbout;
